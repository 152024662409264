import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const SRWHProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();




  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Landscape Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_landscapeArea))} {getQuantityUnit(schema.srwh_landscapeArea)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_concretePavement)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_concretePavement))} {getQuantityUnit(schema.srwh_concretePavement)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_gravelPavement)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_gravelPavement))} {getQuantityUnit(schema.srwh_gravelPavement)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_openGridConcretePavement)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_openGridConcretePavement))} {getQuantityUnit(schema.srwh_openGridConcretePavement)}</Typography>
        </Grid>
      </Grid>


      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_flatSurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_flatSurface))} {getQuantityUnit(schema.srwh_flatSurface)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_vegetationFlatSurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_vegetationFlatSurface))} {getQuantityUnit(schema.srwh_vegetationFlatSurface)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_vegetationOpenGridGrassPavement)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_vegetationOpenGridGrassPavement))} {getQuantityUnit(schema.srwh_vegetationOpenGridGrassPavement)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_vegetationSlightlySlopedSurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_vegetationSlightlySlopedSurface))} {getQuantityUnit(schema.srwh_vegetationSlightlySlopedSurface)}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_vegetationHillySurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_vegetationHillySurface))} {getQuantityUnit(schema.srwh_vegetationHillySurface)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_vegetationSteepSurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_vegetationSteepSurface))} {getQuantityUnit(schema.srwh_vegetationSteepSurface)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_grassSlightlySlopedSurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_grassSlightlySlopedSurface))} {getQuantityUnit(schema.srwh_grassSlightlySlopedSurface)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_grassHillySurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_grassHillySurface))} {getQuantityUnit(schema.srwh_grassHillySurface)}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.srwh_grassSteepSurface)}</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(getQuantityValue(schema.srwh_grassSteepSurface))} {getQuantityUnit(schema.srwh_grassSteepSurface)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SRWHProjectInputs;
