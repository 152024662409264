import numberWithCommas from '../../../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import EnergyFlowDiagram from './EnergyFlowDiagram';
import WasteFlowDiagram from './WasteFlowDiagram';
import WaterFlowDiagram from './WaterFlowDiagram';

const FlowDiagrams = ({ data, name }) => {
  const { getValue, getRoundOffValue } = useAppMetafireUtilities();

  const flowDiagram = data || null;
  const getDataById = (id) => {
    const item = flowDiagram?.find((item) => item.id === id);
    return item ? getRoundOffValue(item.value) : '-Nil-';
  };
  const FlowDiagramMapper = flowDiagram
    ? {
        Energy: (
          <EnergyFlowDiagram
            mainGrid={`${numberWithCommas(getDataById('energyFlowDiagram_totalMainGrid'))}`}
            residentiallighting={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialLighting'))}`}
            residentialhvac={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidenitalHVAC'))}`}
            residentialfan={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialFan'))}`}
            residentialhotwater={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialHotWater'))}`}
            homeappliances={`${numberWithCommas(getDataById('energyFlowDiagram_totalHomeAppliances'))}`}
            commonarea={`${numberWithCommas(getDataById('energyFlowDiagram_totalCommonArea'))}`}
            dieselgenerator={`${numberWithCommas(getDataById('energyFlowDiagram_totalDieselGenerator'))}`}
            solarweaterheater={`${numberWithCommas(getDataById('energyFlowDiagram_totalHotWaterManagement'))}`}
            solarpv={`${numberWithCommas(getDataById('energyFlowDiagram_totalSolarPV'))}`}
            biogas={`${numberWithCommas(getDataById('energyFlowDiagram_biogasToCommonArea'))}`}
            maingridtohotwater={`${numberWithCommas(getDataById('energyFlowDiagram_mainGridToResidentialHotWater'))}`}
            maingridtolighting={`${numberWithCommas(getDataById('energyFlowDiagram_mainGridToResidentialLighting'))}`}
            dieslgeneratortolighting={`${numberWithCommas(getDataById('energyFlowDiagram_dieselGeneratorToResidentialLighting'))}`}
            dieselgeneratortoappliances={`${numberWithCommas(getDataById('energyFlowDiagram_dieselGeneratorToHomeAppliances'))}`}
            dieslgeneratortofan={`${numberWithCommas(getDataById('energyFlowDiagram_dieselGeneratorToResidentialFan'))}`}
            maingridtoresidentialfan={`${numberWithCommas(getDataById('energyFlowDiagram_mainGridToResidentialFan'))}`}
            maingridtohomeappliances={`${numberWithCommas(getDataById('energyFlowDiagram_mainGridToHomeAppliances'))}`}
            maingridtohvac={`${numberWithCommas(getDataById('energyFlowDiagram_mainGridToResidentialHVAC'))}`}
            biogastocommonarea={`${numberWithCommas(getDataById('energyFlowDiagram_biogasToCommonArea'))}`}
            solarwaterheatertoresidentialhotwater={`${numberWithCommas(getDataById('energyFlowDiagram_hwmToResidentialHotWater'))}`}
            solarpvtocommonarea={`${numberWithCommas(getDataById('energyFlowDiagram_spvToCommonArea'))}`}
            maingridtocommonarea={`${numberWithCommas(getDataById('energyFlowDiagram_mainGridToCommonArea'))}`}
            dieslgeneratortocommonarea={`${numberWithCommas(getDataById('energyFlowDiagram_dieselGeneratorToCommonArea'))}`}
          />
        ),
        Waste: (
          <WasteFlowDiagram
            rejectWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_rejectWasteToLandfill'))}`}
            dryWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_dryWasteToRecycler'))}`}
            eWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_eWasteToRecycler'))}`}
            medicalWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_medicalWasteToOffSiteIncinerator'))}`}
            landfill={`${numberWithCommas(getDataById('wasteFlowDiagram_totalLandfill'))}`}
            offSiteIncinerator={`${numberWithCommas(getDataById('wasteFlowDiagram_totalOffSiteIncinerator'))}`}
            recycler={`${numberWithCommas(getDataById('wasteFlowDiagram_totalRecycler'))}`}
            organicWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_totalOrganicWaste'))}`}
            biogas={`${numberWithCommas(getDataById('wasteFlowDiagram_organicWasteToBiogas'))}`}
            naturalComposting={`${numberWithCommas(getDataById('wasteFlowDiagram_organicWasteToNaturalComposting'))}`}
          />
        ),
        Water: (
          <WaterFlowDiagram
            stpRO={`${numberWithCommas(getDataById('waterFlowDiagram_totalStpRo'))}`}
            STP={`${numberWithCommas(getDataById('waterFlowDiagram_totalSTP'))}`}
            swimmingPool={`${numberWithCommas(getDataById('waterFlowDiagram_totalSwimmingPool'))}`}
            domesticOther={`${numberWithCommas(getDataById('waterFlowDiagram_totalDomesticOther'))}`}
            domestiCHealthFaucet={`${numberWithCommas(getDataById('waterFlowDiagram_totalDomesticHealthFaucet'))}`}
            totalRO={`${numberWithCommas(getDataById('waterFlowDiagram_totalRO'))}`}
            FreshWater={`${numberWithCommas(getDataById('waterFlowDiagram_totalFreshWater'))}`}
            WTP={`${numberWithCommas(getDataById('waterFlowDiagram_totalWTP'))}`}
            CommonAreaUse={`${numberWithCommas(getDataById('waterFlowDiagram_totalCommonAreaUse'))}`}
            Flushing={`${numberWithCommas(getDataById('waterFlowDiagram_totalFlushing'))}`}
            Drinking={`${numberWithCommas(getDataById('waterFlowDiagram_totalDrinking'))}`}
          />
        ),
      }
    : {};

  return flowDiagram && FlowDiagramMapper[name] ? FlowDiagramMapper[name] : 'Oops, Flow diagram not available!';
};
export default FlowDiagrams;
