import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import CommonCardNew from '../common/commomCardNew';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';

const useStyles = makeStyles((theme) => ({
  paper2: {
    outline: 'none',
    minHeight: 70,
    minWidth: 160,
    padding: theme.spacing(2, 3, 2),
    marginTop: '1%',
    background: '#FFFFFF',
    borderRadius: '10px',
    height: 'auto',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    position: 'relative',
  },
  typo: {
    cursor: 'pointer',
  },
  spacing: {
    marginTop: theme.spacing(3),
  },
  grid1: {
    marginTop: '2%',
  },
  errorIcon: {
    color: '#66E3BE',
    cursor: 'pointer',
  },
  checkboxlabel: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}));

function InterventionResultsOverview(props) {
  const classes = useStyles();
  const { capexTotalValue, capexTotalConvertedValue, title1, result1, result2, capexConverted, sdgNumbers, sdgText, selectedInterventionDetails } = props;
  const [capex, setCapex] = useState(true);
  const [newinput, setNewInput] = useState([]);

  // TODO: Simplify the logic. Move this filtering to meta object in context
  const opex = result1 && result1.filter((item) => item && item.tags.includes('Opex'));
  const irr = result1 && result1.filter((item) => item && item.label === 'IRR');
  const payback = result1 && result1.filter((item) => item && item.label === 'Payback Period');
  const handleChange = () => {
    setCapex(!capex);
    setNewInput([capexConverted[0], opex[0], irr[0], payback[0]]);
  };

  return (
    <>
      <Grid container direction="row" xs={12} className={classes.grid1}>
        <Grid xs={4} container direction="column" style={{ maxWidth: '100%' }}>
          <Grid container item xs>
            <Typography variant="h5">{props.title1}</Typography>
            <CustomizedTooltips marginTop="15px" icon="info" title={<Typography>View financial & environmental impact</Typography>} placement="right" />
          </Grid>
          <Grid item xs>
            {result1 ? (
              <CommonCardNew
                capex={capex}
                capexTotalValue={capexTotalValue}
                selectedInterventionDetails={selectedInterventionDetails}
                capexTotalConvertedValue={capexTotalConvertedValue}
                title={title1}
                inputs={capex ? result1 : newinput}
                height="165px"
                width="auto"
              />
            ) : (
              <CasaSkeleton variant="rect" height="25vh" width={350} animation="wave" style={{ marginTop: '1%' }} />
            )}
          </Grid>
        </Grid>

        <Grid xs={4} item container direction="column" style={{ maxWidth: '100%', marginLeft: '28px' }}>
          <Grid item xs style={{ paddingTop: '1%' }}>
            <CustomCheckBox checkState={capex} handleChange={handleChange} label={<Typography className={classes.checkboxlabel}>{props.title2}</Typography>} />
          </Grid>
          <Grid item xs>
            {result2 ? (
              <CommonCardNew
                capex={capex}
                capexTotalValue={capexTotalValue}
                capexTotalConvertedValue={capexTotalConvertedValue}
                selectedInterventionDetails={selectedInterventionDetails}
                title1={title1}
                sdgNumbers={sdgNumbers && sdgNumbers}
                sdgText={sdgText && sdgText[0]?.value}
                inputs={result2}
                height="165px"
                width="auto"
                tooltipReady={true}
                tooltipBody={sdgText[0]?.value}
              />
            ) : (
              <CasaSkeleton variant="rect" height="25vh" width={350} animation="wave" style={{ marginTop: '1%' }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InterventionResultsOverview;
