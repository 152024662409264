import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import AdjustIcon from '@material-ui/icons/Adjust';
import RoomIcon from '@material-ui/icons/Room';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';

const PrettoMinSlider = withStyles({
  disabled: {
    color: '#FFA450',
    height: 10,
  },
  thumb: {
    height: 200,
    width: 200,
    backgroundColor: '#FFCB8D',
    marginTop: -5,
    marginLeft: -10,
  },
  track: {
    height: 8,
    borderRadius: 40,
    color: '#FFCB8D',
  },
  rail: {
    height: 8,
    borderRadius: 40,
    color: 'transparent',
  },
  mark: {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    opacity: 0.3,
    height: 9,
    width: 0,
    marginTop: -1,
  },
  markLabel: {
    marginTop: '2%',

    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.428571px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.82)',
  },
})(Slider);

const PrettoMinSlider2 = withStyles({
  disabled: {
    color: '#FFA450',
    height: 10,
  },
  rail: {
    height: 8,
    borderRadius: 40,
    color: '#C9F5FF',
  },
  mark: {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    opacity: 0.3,
    height: 20,
    width: 0,
    marginTop: -6,
  },
  markLabel: {
    marginTop: '2%',

    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '0.428571px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.82)',
  },
})(Slider);

const PrettoMaxSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: 'transparent',
    marginTop: -22,
    marginLeft: -11,
  },
  track: {
    height: 8,
    borderRadius: 40,
    color: '#28DEAA',
  },
  rail: {
    height: 8,
    borderRadius: 40,
    // color: '#C9EDF8',
    color: '#C9F5FF',
  },
})(Slider);

const marks = [
  {
    value: 25,
    label: 'SPROUT',
  },
  {
    value: 50,
    label: 'BLOOM',
  },
  {
    value: 75,
    label: 'GARDEN',
  },
  {
    value: 100,
    label: 'FOREST',
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: '#666666',
    letterSpacing: '1px',
  },
  slider: {
    position: 'relative',
    width: '100%',
  },
}));

const CustomSlider = ({ curr, max }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12} className={classes.slider}>
        <Box bgcolor="background.transparent" p={0} position="absolute" top={-12} width="100%" zIndex={max >= curr ? '0' : '1'}>
          <PrettoMinSlider2 ThumbComponent={AvgThumbComponent} disabled defaultValue={curr} marks={marks} valueLabelDisplay="on" />
        </Box>
        <Box bgcolor="background.transparent" p={0} position="absolute" top={-12} width="100%" zIndex={max >= curr ? '1' : '0'}>
          <PrettoMinSlider ThumbComponent={AvgThumbComponent} disabled defaultValue={curr} marks={marks} valueLabelDisplay="on" />
        </Box>
        <Box bgcolor="background.transparent" p={0} position="absolute" top={-12} width="100%" zIndex={max >= curr ? '0' : '1'}>
          <PrettoMaxSlider ThumbComponent={YouThumbComponent} disabled defaultValue={max} valueLabelDisplay="on" />
        </Box>
      </Grid>
    </>
  );
};

function YouThumbComponent(props) {
  return (
    <span {...props}>
      <RoomIcon style={{ fontSize: 38, color: '#52af77', paddingBottom: 10 }} />
    </span>
  );
}

function AvgThumbComponent(props) {
  return (
    <span {...props}>
      <AdjustIcon style={{ fontSize: 13, color: '#ED5E68' }} />
    </span>
  );
}

export default CustomSlider;
