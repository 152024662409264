import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SimplePIPS from '../../common/components/SimplePIPS';

const RooftopMaterialDetails = ({ data, appendChanged, projectPIP, myArray }) => {
    return (
        <>
            <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
                <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
                    <Typography variant="h5">Rooftop Material Details</Typography>
                </Grid>
            </Grid>
            {data &&
                data.map((pip, index) => {
                    return (
                        <SimplePIPS
                            index={index}
                            unit='Sqm'
                            field={pip && pip}
                            myArray={myArray && myArray}
                            projectPIP={projectPIP && projectPIP}
                            appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                            type='material'
                        />
                    );
                })}
        </>
    );
};

export default RooftopMaterialDetails;
